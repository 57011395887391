import Modal from 'react-modal';

const ImageGridModal = ({ images, isOpen, onClose, doc_BASEURL, setSettingHeroBanner, handleRemoveBannerFunc }) => {
    const modalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            border: 'none',
            borderRadius: '8px',
            maxWidth: '800px', // Adjust as needed
            width: '100%',
        },
    };
    const handleRemoveBanner = (index) => {
        const updatedBanners = images?.filter((_, i) => i !== index);
        setSettingHeroBanner(updatedBanners);
    };
    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                style={modalStyles}
                contentLabel="Image Grid Modal"
            >
                <style>{`
                .image-grid {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 20px;
                    padding: 20px;
                }

                .image-item {
                    position: relative;
                }

                .image-item img {
                    width: 100%;
                    height: auto;
                }

                .remove-button {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    background-color: red;
                    color: white;
                    border: none;
                    border-radius: 50%;
                    cursor: pointer;
                    padding: 5px;
                }

                .close-button {
                    margin-top: 20px;
                    background-color: #0172fd;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    padding: 10px 20px;
                }
            `}</style>
                <div className="image-grid">
                    {images?.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={doc_BASEURL + image} alt={`Image ${index}`} />
                            <button className="remove-button" onClick={() => handleRemoveBanner(index)}>
                                &times;
                            </button>
                        </div>
                    ))}
                </div>
                <button className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto" onClick={onClose}>Close Modal</button>
            </Modal>
        </>
    );
};
export default ImageGridModal;
