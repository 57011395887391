import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb.jsx'
import { gallerySchema } from '../../../../schemas/index.jsx';

import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import GalleryServices from '../../../../ApiServices/GalleryServices.js';

import { Switch } from '@headlessui/react'
import { toast } from 'react-toast';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner.js";
import FileUploadWithPreview from '../../../../components/FileUploadWithPreview.jsx'; // Assuming this is your child component
import { doc_BASEURL } from "../../../../helper/constant.js";
import ImageGridModal from "../settings/ImageGridModal.jsx"
import Modal from 'react-modal';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddGallery = () => {
  const pages = [{ title: "Add Gallery", href: "/gallery", module_id: 40 }];

  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const { gallery_id: gallery_id } = useParams()
  const [gallery_img, setGalleryImg] = useState([]);

  const navigate = useNavigate()
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (gallery_id) {
      getSingleGallery(gallery_id)
    }
  }, [])
  let initialValues = {
    gallery_id: gallery_id,
    gallery_name: "",
    gallery_img: "",
    gallery_is_active: "",
  }

  const [formGallery, setFormGallery] = useState(initialValues);

  const openBannerModal = () => {
    setIsModalOpen(true);
  };

  const closeBannerModal = () => {
    setIsModalOpen(false);
  };
  const handleRemoveBanner = (e, index) => {
    const updatedBanners = gallery_img.filter((_, i) => i !== index);
    console.log("updatedBanners=>", updatedBanners,index);
    setGalleryImg(updatedBanners);
  };
  const handleBannerFileUpload = async (files) => {
    try {
      // Simulate file upload or handle it as needed
      // Example: Send files to backend via API
      console.log('Files to upload PS:', files);

      // Set upload status
      //   setUploadStatus('Files uploaded successfully');
      if (files?.flag == 1) {
        setGalleryImg(files?.files?.dynamicName.split(","))
        toast.success(files?.message);
      } else {
        toast.error(files.message)
      }
    } catch (error) {
      //   setUploadStatus('Error uploading files');
      console.error('Error uploading files:', error);
    }
  };

  const getSingleGallery = (gallery_id) => {
    setIsLoading(true)
    setGalleryImg([])
    GalleryServices.getSingleGallery(gallery_id).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("newSetting", data.data)

        initialValues = {
          gallery_id: parseInt(gallery_id),
          gallery_name: data?.data?.gallery_name,
          gallery_img: data?.data?.gallery_img,
          gallery_is_active: data?.data?.gallery_is_active,

        }
        setGalleryImg(data?.data?.gallery_img.split(","))
        setFormGallery(initialValues)
      }
      setIsLoading(false)
    }).catch((error) => {
      setGalleryImg([]);
      console.log(error)
      setIsLoading(false)
    });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formGallery,
      validationSchema: gallerySchema,
      onSubmit: async (values, action) => {
        console.log("setting_dob", values.gallery_name, errors)

        let body = {
          gallery_id: gallery_id ? gallery_id : null,
          gallery_name: values.gallery_name,
          gallery_img: gallery_img.join(","),
          gallery_is_active: values?.gallery_is_active,

        };
        console.log("body", body)
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        if (gallery_id === undefined || gallery_id === null || gallery_id === "") {
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          await upsertGallery(body);
        } else {
          setIsLoading(true);
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          await upsertGallery(body);

        }
        action.resetForm();
      },
    });
  const upsertGallery = async (payload) => {
    let { data, status } = await GalleryServices.SaveGallery(payload)
    if (status === 200) {
      if (data.status) {
        toast.success(data.message);
      }
      navigate("/gallery");
    } else {
      toast.error(data.message);
    }
    setIsLoading(false);
  }
  return (
    <>
       {/* .image-item img {
                    width: 100%;
                    height: auto;
                } */}
       <style>{`
                .image-grid {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 20px;
                    padding: 20px;
                }

                .image-item {
                    position: relative;
                }

             

                .remove-button {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    background-color: red;
                    color: white;
                    border: none;
                    border-radius: 50%;
                    cursor: pointer;
                    padding: 5px;
                }

                .close-button {
                    margin-top: 20px;
                    background-color: #0172fd;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    padding: 10px 20px;
                }
            `}</style>
      {!isLoading ? (<div>
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Update Gallery</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              <div>
                <label htmlFor="setting_hero_head" className="block text-sm font-medium leading-6 text-gray-900">
                  Hero Head Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.gallery_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Gallery Name"
                    name="gallery_name"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.gallery_name &&
                    touched.gallery_name ? (
                    <p className="text-red-600 text-sm">
                      {errors.gallery_name}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <FileUploadWithPreview onFileUpload={handleBannerFileUpload} bannerName={gallery_img?.length > 0 ? gallery_img : []} multiple={true} />

                  {errors.gallery_img &&
                    touched.gallery_img ? (
                    <p className="text-red-600 text-sm">
                      {errors.gallery_img}
                    </p>
                  ) : null}
                </div>
              </div>
              {gallery_img?.length > 0 && gallery_img.map((image, index) => (
                <div key={index} className="image-item">
                  <img width={40} height={40} src={doc_BASEURL + image} alt={`Image ${index}`} />
                  <button className="remove-button" onClick={(e) =>{e.preventDefault(); handleRemoveBanner(e,index)}}>
                    &times;
                  </button>
                </div>
              ))}




            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
              >
                {gallery_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
              </button>
            </div>
          </form>
        </div>

      </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddGallery