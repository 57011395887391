import { Link } from "react-router-dom";
import { capitalise } from "../../utilities/utilities";
import { Switch } from "@headlessui/react";
import "./style.css"; // import CSS file
import moment from "moment";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CurrencyRupeeIcon, PlusIcon } from "@heroicons/react/20/solid";
import { PencilIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { BsCurrencyRupee } from "react-icons/bs";

import {
  calculateAge,
} from "../../helper/commonHelper";

import { FaDownload, FaSpinner } from 'react-icons/fa';

function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function magazineStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineIssueStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function productStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const user_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.user_first_name) + " " + capitalise(d.user_last_name),
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Mobile",
      accessor: "user_mobile",
    },
    {
      Header: "Role",
      accessor: (d) => capitalise(d.m_role_relation?.role_name),
    },
    {
      Header: "Status",
      accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const enabled = cell.row.original.user_is_active;
        const isBtn = cell.row.original.isBtn;

        if (
          allowedActions?.filter((data) => data.permission_id === 12)?.length >
          0 &&
          isBtn
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 11);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 13);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }

        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id == 11)?.length > 0 && isBtn) || localStorage.getItem("user_id") == cellId ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {(allowedActions.filter((data) => data.permission_id == 13)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];

export const role_columns = ({
  onDeleteOpen,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: (d) => capitalise(d.role_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.role_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {/* {isBtn && ( */}
            {(allowedActions.filter((data) => data.permission_id == 4)?.length >
              0 && isBtn) || (localStorage.getItem("role_id") == 1) ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 5)?.length >
              0 && isBtn ? (
              // {isBtn && (
              <Link
                to={`/role/${cellId}`}
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit Permissions
                </button>
              </Link>
            ) : null}

            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 6)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];

export const contactUs_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "User Name",
    accessor: (d) => capitalise(d.conus_name),
  },
  {
    Header: "Email",
    accessor: (d) => capitalise(d.conus_email),
  },
  {
    Header: "Mobile No",
    accessor: "conus_mobile",
  },

  {
    Header: "Question",
    accessor: "conus_question",
  },
  // {
  //   Header: "Description",
  //   accessor: "conus_description",
  // },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.conus_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 23)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 25)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const testimonial_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "User Name",
    accessor: (d) => capitalise(d.tn_username),
  },
  {
    Header: "Designation",
    accessor: (d) => capitalise(d.tn_designation),
  },
  // {
  //   Header: "Comment",
  //   accessor: "tn_comment",
  // },

  {
    Header: "Rating",
    accessor: "tn_star_rating",
    Cell: (cell) => {
      const rating = cell?.row?.original?.tn_star_rating;

      const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
          if (i <= rating) {
            stars.push(
              <span key={i} className="text-yellow-500">
                &#9733;
              </span>
            ); // Unicode star character
          } else {
            stars.push(
              <span key={i} className="text-gray-500">
                &#9734;
              </span>
            ); // Unicode empty star character
          }
        }
        return stars;
      };

      return (
        <span className="sm:text-sm text-gray-500">{renderStars(rating)}</span>
      );
    },
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.tn_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 29)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 31)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const fact_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Head",
    accessor: (d) => capitalise(d.fact_head),
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.fact_name),
  },
  // {
  //   Header: "Description",
  //   accessor: "fact_description",
  // },

  {
    Header: "Position",
    accessor: "fact_is_position",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.fact_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 53)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 55)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const brand_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },

  {
    Header: "Name",
    accessor: (d) => capitalise(d.brand_name),
  },
  // {
  //   Header: "Description",
  //   accessor: "brand_description",
  // },

  {
    Header: "Position",
    accessor: "brand_is_position",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.brand_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 17)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 19)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const cat_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.cat_name),
  },
  // {
  //   Header: "Description",
  //   accessor: "cat_description",
  // },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.cat_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 59)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 61)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const blog_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Title",
    accessor: (d) => capitalise(d.blog_title),
  },
  {
    Header: "Content",
    accessor: "blog_content",
  },

  {
    Header: "Slug",
    accessor: "blog_slug",
  },
  {
    Header: "Keyword",
    accessor: "blog_keyword",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.blog_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 35)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 37)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];
export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Gender English",
    accessor: (d) => capitalise(d.gender_name),
  },
  {
    Header: "Gender Arabic",
    accessor: (d) => capitalise(d.gender_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const service_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Service Name",
    accessor: (d) => capitalise(d.serv_name),
  },
  {
    Header: "Service Price",
    accessor: 'serv_amt',
  },
  {
    Header: "Service Currency",
    accessor: 'serv_currency',
  },
  // {
  //   Header: "Service Desc.",
  //   accessor: 'serv_description',
  // },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.serv_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 41)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>
          ) : null}
          {/*  */}

          {allowedActions.filter((data) => data.permission_id == 43)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const doctor_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Doctor Id",
      accessor: "doctor_id",
    },
    {
      Header: "Doctor Name",
      accessor: (d) => capitalise(d?.doctor_name),
    },
    {
      Header: "Doctor Email",
      accessor: (d) => capitalise(d?.doctor_email),
    },
    {
      Header: "Doctor Mobile",
      accessor: (d) => capitalise(d?.doctor_mobile),
    },
    {
      Header: "Doctor Gender",
      accessor: (d) => capitalise(d?.doctor_gender),
    },
    {
      Header: "Doctor Designation",
      accessor: (d) => capitalise(d?.doctor_designation),
    },

    // {
    //   Header: "Doctor Description",
    //   accessor: (d) => capitalise(d?.doctor_description), //capitalise(d.order_date),
    // },
    {
      Header: "Status",
      accessor: (d) => (d.ord_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.doctor_id;
        const enabled = cell.row.original.doctor_is_active;

        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 48)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.doctor_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.doctor_id;
        const doctor_is_active = cell?.row?.original?.doctor_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 47)?.length >
              0 ? (
              <Link
                to={`../add-doctor/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 49)?.length >
              0 ? (
              <span className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId, doctor_is_active);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];


export const setting_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "System Id",
      accessor: "setting_id",
    },
    {
      Header: "Hero Head",
      accessor: (d) => capitalise(d?.setting_hero_head),
    },


    // {
    //   Header: "Hero Description",
    //   accessor: (d) => capitalise(d?.setting_hero_description), //capitalise(d.order_date),
    // },
    {
      Header: "Status",
      accessor: (d) => (d.setting_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.setting_id;
        const enabled = cell.row.original.setting_is_active;

        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 66)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.setting_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.setting_id;
        const setting_is_active = cell?.row?.original?.setting_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 65)?.length >
              0 ? (
              <Link
                to={`../add-sys-config/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full"
              >
                <button
                  className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 67)?.length >
              0 ? (
              <span className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId, setting_is_active);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];


export const gallery_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.gallery_name),
  },
  // {
  //   Header: "Description",
  //   accessor: "gallery_description",
  // },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gallery_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 71)?.length >
            0 ? (
            <Link
              to={`../update-gallery/${cellId}`}
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 73)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];