import { toast } from "react-toast";
import {
	GET_USER_INFO_BEGIN,
	GET_USER_INFO_SUCCESS,
	GET_USER_INFO_ERROR,
} from "./constants";
import Api from "../../ApiServices/Api";
import { handleLogout } from "../auth/actions";

export const getLoginUserInfo = (payload) => async (dispatch) => {
	try {
		dispatch({
			type: GET_USER_INFO_BEGIN,
		});
		const { data } = await Api().get(`/api/dashboard/user/${payload}`);

		dispatch({
			type: GET_USER_INFO_SUCCESS,
			data: data ? data?.data : {},
		});
		if (!data?.data) {
			dispatch(handleLogout());
			window.location.reload();
		}
	} catch (error) {
		console.log(error?.response);
		const msg = error?.response?.data?.message;
		toast.error(msg);
		dispatch({
			type: GET_USER_INFO_ERROR,
			error: msg,
		});
	}
};