import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { user_columns } from "../../../components/tables/tableheader";
import {
  deleteUser,
  getAllUsers,
  addUser,
  updateUser,
} from "../../../redux/user/actions";

import { getAllRoles } from "../../../redux/role/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { FileUploader } from "react-drag-drop-files";
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";

function UserList() {
  const pages = [{ title: "User List", href: "/user-list", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [user_id, setUserid] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileCountryCode, setMobileCountryCode] = useState('')
  const [initialRender, setInitialRender] = useState(true);
  const [selectedUserRoleId, setSelectedUserRoleId] = useState({ value: '', label: '' });
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    user_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    user_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { loading: userLoading, list: userList },
    role: { list: roleList },
  } = useSelector((state) => state);

  const [pathname, setPathname] = useState('');
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  //Mobile number validation in patient
  useEffect(() => {

    if (!initialRender) {
      // console.log(mobileNumber.toString())
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
        const phoneNumber = parsePhoneNumber(mobileNumber)
        console.log(phoneNumber)
        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setUserMobileNumber(phoneNumber.nationalNumber);
        }
        setMobileNumberValid(true);
      } else {
        setMobileNumberValid(false)
      }
    } else {
      setInitialRender(false)
    }
  }, [mobileNumber])


  useEffect(() => {
    document.title = "S.N. Hospital  • Users";
    setIsLoading(true)
    dispatch(getAllUsers(3));
    dispatch(getAllRoles());
    setIsLoading(false)
  }, []);

  const onStatusUpdate = (user_id, user_is_active) => {
    let payload = {
      table_name: "m_user",
      table_field: "user_id",
      table_field_val: user_id,
      table_status_field: "user_is_active",
      table_status_val: user_is_active ? false : true,
      table_text: "User",
      deleted_by_key: "user_updated_by",
      delete_active_txt: user_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        dispatch(getAllUsers(3));
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  const userSchema = Yup.object({
    user_username: Yup.string()
      .min(3)
      .max(25)
      .required("Please Enter the Username"),
    user_first_name: Yup.string()
      .min(3)
      .max(25)
      .required("Please Enter the First Name"),
    user_last_name: Yup.string()
      .min(2)
      .max(25)
      .required("Please Enter the Last Name"),
    user_email: Yup.string().email().required("Please Enter the Email address"),
    user_password: user_id ? Yup.string() : Yup.string().min(6).required("Please Enter the Password"),
    user_mobile: Yup.string()
      .min(10)
      .max(10)
      .required("Please Enter the Mobile Number"),
    fk_role_id: Yup.string().required("Please Enter the Role"),

  });
  let DeleteUserID = 0;

  let initialValues = {
    user_id: "",
    user_username: "",
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile: "",
    fk_role_id: "",
    user_password: "",
    user_is_active: true,
  };

  const [formUser, setFormUser] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setSelectedUserRoleId('')
    setSelectedUserId('')
    values.fk_role_id = ""
    setMobileNumberValid(true);

    if ((type === "edit", id)) {
      setUserid(id);
      let mergedPhone = `+${obj.user_dial_code}${obj.user_mobile}`;
      if (obj.user_mobile && obj.user_dial_code && isValidPhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)) {
        const phoneNumber = parsePhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)
        console.log(phoneNumber)
        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setMobileNumber(phoneNumber.number);
        }
      }
      const initialValues = {
        user_id: obj.user_id,
        user_username: obj.user_username,
        user_first_name: obj.user_first_name,
        user_last_name: obj.user_last_name,
        user_email: obj.user_email,
        user_mobile: obj.user_mobile,
        fk_role_id: obj.fk_role_id,
        user_is_active: obj.user_is_active,

      };

      if (Array.isArray(roleList) && roleList.length > 0) {
        let role = roleList.filter(v => v.role_id == initialValues.fk_role_id)
        if (role.length > 0) {
          setSelectedUserRoleId({ value: role[0].role_id, label: role[0].role_name })
        }
      }
      setFormUser(initialValues);
    } else {
      setMobileNumber("");
      setUserid("");
      setFormUser(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (user_id, user_is_active) => {
    setConfirmationModal({ user_id, user_is_active, status: true });
  };

  const onDeleteOpenSection = (user_id, user_is_deleted) => {
    DeleteUserID = user_id;
    setRemoveConfirmationModal({ user_id, user_is_deleted, status: true });
  };

  const setSelectedRole = async (events) => {
    console.log("setSelectedRole events", events.value);
    let cid = events.value ? events.value : null
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formUser,
      validationSchema: userSchema,
      onSubmit: async (values, action) => {

        let body = {
          user_id: user_id,
          user_username: values.user_username,
          user_first_name: values.user_first_name,
          user_last_name: values.user_last_name,
          user_email: values.user_email,
          user_mobile: values.user_mobile,
          dial_code: 91,
          fk_role_id: values.fk_role_id,
          user_password: values.user_password,
          user_is_active: true,
        };

        console.log("body", body, errors);
        //return;

        if (body.user_first_name != undefined || body.user_first_name != null || body.user_first_name != "") {
          // setIsLoading(true)
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          let { data, status } = await MasterServices.saveMUser(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              setMobileNumber('');
              setMobileCountryCode('');
              dispatch(getAllUsers(3));
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)

            } else {
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            dispatch(getAllUsers(3));
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeleteUser = (user_id) => {
    dispatch(deleteUser(user_id));
    dispatch(getAllUsers(3));
    setRemoveConfirmationModal({ user_id: null, status: false });
  };

  const filterRole = (role) => {
    const result = roleList.filter(obj => obj?.role_name?.toLowerCase() === role?.toLowerCase());
    let resp = null;
    if (result.length > 0) {
      resp = result[0].role_id
    } else {
      resp = undefined;
    }
    return resp;
  }
  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("User handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {

            rows.map((obj, index) => {
              if (obj.user_username != undefined || obj.user_first_name != undefined) {
                const newObj = {
                  user_username: obj.user_username !== undefined ? obj.user_username ? obj.user_username.trim() : obj.user_username.trim() : '',
                  user_password: obj.user_password !== undefined ? obj.user_password ? obj.user_password.toString().trim() : obj.user_password.toString().trim() : '',
                  user_first_name: obj.user_first_name !== undefined ? obj.user_first_name ? obj.user_first_name.trim() : obj.user_first_name.trim() : '',
                  user_last_name: obj.user_last_name !== undefined ? obj.user_last_name ? obj.user_last_name.trim() : obj.user_last_name.trim() : '',
                  user_email: obj.user_email !== undefined ? obj.user_email ? obj.user_email.trim() : obj.user_email.trim() : '',
                  user_mobile: obj.user_mobile !== undefined ? obj?.user_mobile ? parseInt(obj?.user_mobile + "") : parseInt(obj?.user_mobile + "") : '',
                  RoleName: obj.role_name !== undefined ? obj.role_name ? obj.role_name.trim() : obj.role_name.trim() : '',
                  fk_role_id: filterRole(obj.role_name),
                };

                newData.push(newObj);
              }

            });

            console.log("newArray: ", newData);
            const filteredArray = newData.filter(obj => obj.fk_role_id !== undefined);
            console.log("filteredArray: ", filteredArray);

            if (filteredArray.length > 0) {
              importUser(filteredArray);
            }

          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importUser = (data) => {
    const payload = { excel_data: data }
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("User Imported Successfully");
          dispatch(getAllUsers(3));
        } else {
          toast.error(data.message)
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const handleExport = (excel_type) => {
    if (!userList || userList.length === 0 || !roleList || roleList.length === 0) {
      toast.error("User list or role list is empty!");
      return;
    }
    if (userList?.length > 0) {
      let newSheetdata = [];
      userList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          user_id: obj?.user_id || '-',
          user_username: capitalizeFirstLetter(obj?.user_username) || '-',
          user_first_name: capitalizeFirstLetter(obj?.user_first_name) || '-',
          user_last_name: capitalizeFirstLetter(obj?.user_last_name) || '-',
          user_email: obj?.user_email || '-',
          user_mobile: obj?.user_mobile || '-',
          role_name: obj?.m_role_relation?.role_name || '-',
          user_created_at: moment(obj?.user_created_at).format("DD-MM-YYYY") || '-',
          Status: obj?.user_is_active == true ? 'Active' : 'In-Active' || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'User_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

      const headings = [['SN', 'User ID', 'User Name', 'User First Name', 'User Last Name', 'User Email', 'User Mobie', 'Role', 'Created Date', 'Status']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }



  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.user_is_active ? "Disable " : "Enable") +
          " this User?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.user_id,
            confirmationModal.user_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.user_is_deleted
            ? " Undo"
            : "Delete") +
          " this User?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteUser(removeConfirmationModal.user_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">User List</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Users.</p>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 10)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#11ffdb] focus:outline-none focus:ring-2 focus:ring-[#0172fd] focus:ring-offset-2 ml-auto"
          >
            Add User
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 84)?.length > 0 && userList?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#0172fd] focus:outline-none focus:ring-2 focus:ring-[#0172fd] focus:ring-offset-2 ml-2"
          >
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 85)?.length > 0 ? (
          <label htmlFor="file-upload" className="inline-flex items-center justify-center rounded-md bg-[#0172fd] px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-[#0172fd] focus:outline-none focus:ring-2 focus:ring-[#0172fd] focus:ring-offset-2 ml-2 cursor-pointer transition duration-300 ease-in-out">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
              <path fillRule="evenodd" d="M12 10a4 4 0 11-8 0 4 4 0 018 0zm2-2a6 6 0 11-12 0 6 6 0 0112 0z" clipRule="evenodd" />
              <path d="M10 2a8 8 0 00-8 8c0 4.418 3.582 8 8 8s8-3.582 8-8a8 8 0 00-8-8zM5.293 8.707a1 1 0 011.414 0L10 11.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
            Upload User
            <input id="file-upload" type="file" className="hidden" onChange={handleImport} />
          </label>
        ) : null}
      </div>

      {userLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={user_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={userList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {user_id ? "Update" : "Add"} User
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="user_username"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Username
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Username"
                                    name="user_username"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_username &&
                                    touched.user_username ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_username.replace("user_username", "Username")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    First Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="First Name"
                                    name="user_first_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_first_name &&
                                    touched.user_first_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_first_name.replace("user_first_name", "First Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Last Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Last Name"
                                    name="user_last_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_last_name &&
                                    touched.user_last_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_last_name.replace("user_last_name", "Last Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    User Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="email"
                                    placeholder="User Email"
                                    name="user_email"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={user_id ? true : false}
                                  />
                                  {errors.user_email && touched.user_email ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_email.replace("user_email", "Email")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    User Mobile
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Mobile"
                                    name="user_mobile"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={user_id ? true : false}
                                  />
                                  {errors.user_mobile && touched.user_mobile ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_mobile.replace("user_mobile", "Mobile")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Password
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.user_password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    placeholder="Enter Password"
                                    name="user_password"
                                    autoComplete="new-password"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.user_password &&
                                    touched.user_password ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_password.replace("user_password", "Password")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {roleList && roleList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="role_name"
                                    id="role_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select Role
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedUserRoleId}
                                    onChange={(e) => {
                                      console.log(e);
                                      values.fk_role_id = e.value;
                                      setSelectedUserRoleId(e);
                                      setSelectedRole(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      roleList
                                        ? roleList.map((v) => ({
                                          value: v.role_id,
                                          label: v.role_name,
                                        }))
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_role_id && touched.fk_role_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_role_id.replace("fk_role_id", "User Role")}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : "bg-cyan-700"
                              }`}
                          >
                            {user_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default UserList;