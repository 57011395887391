import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { settingSchema } from '../../../../schemas';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import SettingServices from '../../../../ApiServices/SettingServices';
import makeAnimated from "react-select/animated";
import { Switch } from '@headlessui/react'
import { toast } from 'react-toast';
import moment from 'moment';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import FileUploadWithPreview from '../../../../components/FileUploadWithPreview'; // Assuming this is your child component
import { doc_BASEURL } from "../../../../helper/constant.js";
import ImageGridModal from "./ImageGridModal.jsx"
import Modal from 'react-modal';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddSetting = () => {
  const pages = [{ title: "Add Setting", href: "/add-sys-config", module_id: 40 }];
  const [settingIsActive, setSettingIsActive] = useState(true)
  const [banner, setBanner] = useState(null);
  const [tempBanner, setTempBanner] = useState(null);
  const [base64Banner, setBase64Banner] = useState(null);

  const [setting_logo, setSettingLogo] = useState(null);
  const [setting_hero_banner, setSettingHeroBanner] = useState([]);
  const [setting_about_sign, setSettingSign] = useState(null);

  const [logo, setLogo] = useState(null);
  const [tempLogo, setTempLogo] = useState(null);
  const [base64Logo, setBase64Logo] = useState(null);

  const [isLoading, setIsLoading] = useState(false)
  const { setting_id: setting_id } = useParams()
  const animatedComponents = makeAnimated();
  const navigate = useNavigate()
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (setting_id) {
      getSingleSetting(setting_id)
    }
  }, [])

  const openBannerModal = () => {
    setIsModalOpen(true);
  };

  const closeBannerModal = () => {
    setIsModalOpen(false);
  };
  let initialValues = {
    setting_id: null,
    setting_hero_head: "",
    setting_email: "",
    setting_mobile: "",
    setting_hero_description: "",
    setting_hero_btn_txt: "",
    setting_hero_btn_link: "",
    setting_hero_banner: "",
    setting_logo: "",
    setting_google_loc: "",
    setting_about_head: "",
    setting_about_designation: "",
    setting_about_name: "",
    setting_about_sign: "",
    setting_about_desc: "",
    setting_sec_one_head: "",
    setting_sec_one_icon: "",
    setting_sec_one_btn_title: "",
    setting_sec_one_btn_link: "",
    setting_sec_one_desc: "",
    setting_sec_two_head: "",
    setting_sec_two_icon: "",
    setting_sec_two_btn_title: "",
    setting_sec_two_btn_link: "",
    setting_sec_two_desc: "",
    setting_sec_three_head: "",
    setting_sec_three_icon: "",
    setting_sec_three_desc: "",
    setting_is_active: false,
  }
  const [settingForm, setSettingForm] = useState(initialValues)

  const handleLogoFileUpload = async (files) => {
    try {
      // Simulate file upload or handle it as needed
      // Example: Send files to backend via API
      console.log('Files to upload PS:', files);

      // Set upload status
      //   setUploadStatus('Files uploaded successfully');
      if (files?.flag == 1) {
        setSettingLogo(files?.files?.dynamicName)
        toast.success(files?.message);
      } else {
        toast.error(files.message)
      }
    } catch (error) {
      //   setUploadStatus('Error uploading files');
      console.error('Error uploading files:', error);
    }
  };

  const handleBannerFileUpload = async (files) => {
    try {
      // Simulate file upload or handle it as needed
      // Example: Send files to backend via API
      console.log('Files to upload PS:', files);

      // Set upload status
      //   setUploadStatus('Files uploaded successfully');
      if (files?.flag == 1) {
        setSettingHeroBanner(files?.files?.dynamicName.split(","))
        toast.success(files?.message);
      } else {
        toast.error(files.message)
      }
    } catch (error) {
      //   setUploadStatus('Error uploading files');
      console.error('Error uploading files:', error);
    }
  };
  const handleSignFileUpload = async (files) => {
    try {
      // Simulate file upload or handle it as needed
      // Example: Send files to backend via API
      console.log('Files to upload PS:', files);

      // Set upload status
      //   setUploadStatus('Files uploaded successfully');
      if (files?.flag == 1) {
        setSettingSign(files?.files?.dynamicName)
        toast.success(files?.message);
      } else {
        toast.error(files.message)
      }
    } catch (error) {
      //   setUploadStatus('Error uploading files');
      console.error('Error uploading files:', error);
    }
  };

  const getSingleSetting = (setting_id) => {
    setIsLoading(true)

    setBase64Banner(null);
    setBase64Logo(null);
    SettingServices.getSingleSetting(setting_id).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("newSetting", data.data)

        initialValues = {
          setting_id: parseInt(setting_id),
          setting_hero_head: data?.data?.setting_hero_head,
          setting_email: data?.data?.setting_email,
          setting_mobile: data?.data?.setting_mobile,
          setting_hero_description: data?.data?.setting_hero_description,
          setting_hero_btn_txt: data?.data?.setting_hero_btn_txt,
          setting_hero_btn_link: data?.data?.setting_hero_btn_link,
          setting_hero_banner: data?.data?.setting_hero_banner,
          setting_logo: data?.data?.setting_logo,
          setting_google_loc: data?.data?.setting_google_loc,
          setting_about_head: data?.data?.setting_about_head,
          setting_about_designation: data?.data?.setting_about_designation,
          setting_about_name: data?.data?.setting_about_name,
          setting_about_sign: data?.data?.setting_about_sign,
          setting_about_desc: data?.data?.setting_about_desc,
          setting_sec_one_head: data?.data?.setting_sec_one_head,
          setting_sec_one_icon: data?.data?.setting_sec_one_icon,
          setting_sec_one_btn_title: data?.data?.setting_sec_one_btn_title,
          setting_sec_one_btn_link: data?.data?.setting_sec_one_btn_link,
          setting_sec_one_desc: data?.data?.setting_sec_one_desc,
          setting_sec_two_head: data?.data?.setting_sec_two_head,
          setting_sec_two_icon: data?.data?.setting_sec_two_icon,
          setting_sec_two_btn_title: data?.data?.setting_sec_two_btn_title,
          setting_sec_two_btn_link: data?.data?.setting_sec_two_btn_link,
          setting_sec_two_desc: data?.data?.setting_sec_two_desc,
          setting_sec_three_head: data?.data?.setting_sec_three_head,
          setting_sec_three_icon: data?.data?.setting_sec_three_icon,
          setting_sec_three_desc: data?.data?.setting_sec_three_desc,

        }
        setSettingLogo(data?.data?.setting_logo);
        setSettingHeroBanner(data?.data?.setting_hero_banner.split(","));
        setSettingSign(data?.data?.setting_about_sign);
        console.log("initialValues", initialValues)
        setTempBanner(data?.data?.setting_hero_banner);
        setTempLogo(data?.data?.setting_logo);
        setSettingForm(initialValues)
      }
      setIsLoading(false)
    }).catch((error) => {
      setBanner(null);
      setLogo(null);
      console.log(error)
      setIsLoading(false)
      setSettingLogo(null);
      setSettingHeroBanner(null);
      setSettingSign(null);
    });
  }
  const handleRemoveBanner = (e, index) => {
    const updatedBanners = setting_hero_banner.filter((_, i) => i !== index);
    setSettingHeroBanner(updatedBanners);
  };
  // const onBannerFileChange = (e) => {
  //   const file = e.target.files[0];
  //   const fileName = file.name;

  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     setBanner(file)
  //     setTempBanner(e.target.result)
  //     setBase64Banner(e.target.result)
  //   };
  //   reader.readAsDataURL(file);
  // };
  // const onLogoFileChange = (e) => {
  //   const file = e.target.files[0];
  //   const fileName = file.name;

  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     setLogo(file)
  //     setTempLogo(e.target.result)
  //     setBase64Logo(e.target.result)
  //   };
  //   reader.readAsDataURL(file);
  // };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: settingForm,
      validationSchema: settingSchema,
      onSubmit: async (values, action) => {
        console.log("setting_dob", values.setting_hero_head, errors)
        let body = {
          setting_id: setting_id ? setting_id : null,
          setting_hero_head: values.setting_hero_head,
          setting_email: values.setting_email,
          setting_mobile: values.setting_mobile,
          setting_hero_description: values.setting_hero_description,
          setting_hero_btn_txt: values.setting_hero_btn_txt,
          setting_hero_btn_link: values.setting_hero_btn_link,
          // setting_hero_banner: base64Banner,
          // setting_logo: base64Logo,
          setting_hero_banner: setting_hero_banner.join(","),
          setting_logo: setting_logo,
          setting_about_sign: setting_about_sign,
          setting_google_loc: values.setting_google_loc,
          setting_about_head: values.setting_about_head,
          setting_about_designation: values.setting_about_designation,
          setting_about_name: values.setting_about_name,
          // setting_about_sign: values.setting_about_sign,
          setting_about_desc: values.setting_about_desc,
          setting_sec_one_head: values.setting_sec_one_head,
          setting_sec_one_icon: values.setting_sec_one_icon,
          setting_sec_one_btn_title: values.setting_sec_one_btn_title,
          setting_sec_one_btn_link: values.setting_sec_one_btn_link,
          setting_sec_one_desc: values.setting_sec_one_desc,
          setting_sec_two_head: values.setting_sec_two_head,
          setting_sec_two_icon: values.setting_sec_two_icon,
          setting_sec_two_btn_title: values.setting_sec_two_btn_title,
          setting_sec_two_btn_link: values.setting_sec_two_btn_link,
          setting_sec_two_desc: values.setting_sec_two_desc,
          setting_sec_three_head: values.setting_sec_three_head,
          setting_sec_three_icon: values.setting_sec_three_icon,
          setting_sec_three_desc: values.setting_sec_three_desc,
          setting_is_active: settingIsActive,

        };
        console.log("body", body)
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        const response = await SettingServices.SaveSetting(body);
        if (response.data.status) {
          action.resetForm();
          setSettingForm(initialValues);
          toast.success(response.data.message);
          setDisableSubmitButton(false)
          navigate("/sys-config");
        } else {
          toast.error(response.data.message);
          setDisableSubmitButton(false)
        }
      },
    });
  console.log("setting_dob", errors)
  return (
    <>
      {!isLoading ? (<div>
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Add Setting</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              <div>
                <label htmlFor="setting_hero_head" className="block text-sm font-medium leading-6 text-gray-900">
                  Hero Head Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_hero_head"
                    id="setting_hero_head"
                    value={values.setting_hero_head}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Hero Head Name"
                  />
                  {errors.setting_hero_head && touched.setting_hero_head ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_hero_head}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_email"
                    id="setting_email"
                    value={values.setting_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Enter Email"
                  />
                  {errors.setting_email && touched.setting_email ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_email}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_mobile" className="block text-sm font-medium leading-6 text-gray-900">
                  Mobile<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_mobile"
                    id="setting_mobile"
                    value={values.setting_mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Enter Mobile"
                  />
                  {errors.setting_mobile && touched.setting_mobile ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_mobile}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_description" className="block text-sm font-medium leading-6 text-gray-900">
                  Hero Description
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.setting_hero_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Description"
                    name="setting_hero_description"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.setting_hero_description &&
                    touched.setting_hero_description ? (
                    <p className="text-red-600 text-sm">
                      {errors.setting_hero_description}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_hero_btn_txt" className="block text-sm font-medium leading-6 text-gray-900">
                  Hero Button Text
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_hero_btn_txt"
                    id="setting_hero_btn_txt"
                    value={values.setting_hero_btn_txt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Hero Button Text"
                  />
                  {errors.setting_hero_btn_txt && touched.setting_hero_btn_txt ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_hero_btn_txt}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_hero_btn_link" className="block text-sm font-medium leading-6 text-gray-900">
                  Hero Button Link
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_hero_btn_link"
                    id="setting_hero_btn_link"
                    value={values.setting_hero_btn_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Hero Button Link"
                  />
                  {errors.setting_hero_btn_link && touched.setting_hero_btn_link ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_hero_btn_link}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_hero_banner" className="block text-sm font-medium leading-6 text-gray-900">
                  Hero Banner
                </label>
                <div className="">
                  <FileUploadWithPreview onFileUpload={handleBannerFileUpload} bannerName={setting_hero_banner?.length > 0 ? setting_hero_banner : []} multiple={true} />
                  <button className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 mt-2 text-xs font-medium text-white shadow-sm hffff  ml-auto" onClick={(e) => { e.preventDefault(); openBannerModal() }}>View</button>
                  <ImageGridModal
                    images={setting_hero_banner}
                    isOpen={isModalOpen}
                    onClose={closeBannerModal}
                    doc_BASEURL={doc_BASEURL}
                    setSettingHeroBanner={setSettingHeroBanner}
                    handleRemoveBanner={handleRemoveBanner}
                  />
                  {/* {setting_hero_banner.map((preview, index) => (
                    <div key={index} style={{ display: 'flex inline-block', position: 'relative', margin: '5px' }}>
                      <br /><img height={40} width={40} className="preview-image" src={doc_BASEURL + preview} alt={`preview-${index}`} />
                      <button
                        style={{
                          position: 'absolute',
                          top: '35px',
                          right: '255px',
                          backgroundColor: 'red',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer'
                        }}
                        onClick={(e) => { e.preventDefault(); handleRemoveBanner(e, index) }}
                      >
                        &times;
                      </button>

                    </div>
                  ))} */}

                  {errors.setting_hero_banner && touched.setting_hero_banner ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_hero_banner}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_logo" className="block text-sm font-medium leading-6 text-gray-900">
                  Logo
                </label>
                <div className="">
                  <FileUploadWithPreview onFileUpload={handleLogoFileUpload} bannerName={setting_logo} multiple={false} />
                  {setting_logo ? (<img height={40} width={40} className="preview-image" src={doc_BASEURL + setting_logo} alt={`preview-${setting_logo}`} />) : (<></>)}

                  {errors.setting_logo && touched.setting_logo ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_logo}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="setting_google_loc" className="block text-sm font-medium leading-6 text-gray-900">
                  Setting Google Loc
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_google_loc"
                    id="setting_google_loc"
                    value={values.setting_google_loc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Setting Google Loc"
                  />
                  {errors.setting_google_loc && touched.setting_google_loc ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_google_loc}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_about_head" className="block text-sm font-medium leading-6 text-gray-900">
                  Setting About Head<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_about_head"
                    id="setting_about_head"
                    value={values.setting_about_head}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Setting About Head"
                  />
                  {errors.setting_about_head && touched.setting_about_head ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_about_head}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="setting_about_designation" className="block text-sm font-medium leading-6 text-gray-900">
                  Setting About Designation
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_about_designation"
                    id="setting_about_designation"
                    value={values.setting_about_designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Setting About Designation"
                  />
                  {errors.setting_about_designation && touched.setting_about_designation ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_about_designation}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="setting_about_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Setting About Name
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_about_name"
                    id="setting_about_name"
                    value={values.setting_about_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Setting About Name"
                  />
                  {errors.setting_about_name && touched.setting_about_name ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_about_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_about_sign" className="block text-sm font-medium leading-6 text-gray-900">
                  About Sign
                </label>
                <div className="">
                  <FileUploadWithPreview onFileUpload={handleSignFileUpload} bannerName={setting_about_sign} multiple={false} />
                  {setting_about_sign ? (<img height={40} width={40} className="preview-image" src={doc_BASEURL + setting_about_sign} alt={`preview-${setting_about_sign}`} />) : (<></>)}

                  {errors.setting_about_sign && touched.setting_about_sign ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_about_sign}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_about_desc" className="block text-sm font-medium leading-6 text-gray-900">
                  Setting About Description
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.setting_about_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter About Description"
                    name="setting_about_desc"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.setting_about_desc &&
                    touched.setting_about_desc ? (
                    <p className="text-red-600 text-sm">
                      {errors.setting_about_desc}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="setting_sec_one_head" className="block text-sm font-medium leading-6 text-gray-900">
                  First Head
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_one_head"
                    id="setting_sec_one_head"
                    value={values.setting_sec_one_head}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="First Head"
                  />
                  {errors.setting_sec_one_head && touched.setting_sec_one_head ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_one_head}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_one_icon" className="block text-sm font-medium leading-6 text-gray-900">
                  First Head Icon
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_one_icon"
                    id="setting_sec_one_icon"
                    value={values.setting_sec_one_icon}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder=" First Head Icon"
                  />
                  {errors.setting_sec_one_icon && touched.setting_sec_one_icon ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_one_icon}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_one_btn_title" className="block text-sm font-medium leading-6 text-gray-900">
                  First Head Button Title
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_one_btn_title"
                    id="setting_sec_one_btn_title"
                    value={values.setting_sec_one_btn_title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="First Head Button Title"
                  />
                  {errors.setting_sec_one_btn_title && touched.setting_sec_one_btn_title ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_one_btn_title}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_one_btn_link" className="block text-sm font-medium leading-6 text-gray-900">
                  First Head Button Link
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_one_btn_link"
                    id="setting_sec_one_btn_link"
                    value={values.setting_sec_one_btn_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="First Head Button Link"
                  />
                  {errors.setting_sec_one_btn_link && touched.setting_sec_one_btn_link ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_one_btn_link}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_one_desc" className="block text-sm font-medium leading-6 text-gray-900">
                  First Head Desc.
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.setting_sec_one_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter First Head Desc."
                    name="setting_sec_one_desc"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.setting_sec_one_desc &&
                    touched.setting_sec_one_desc ? (
                    <p className="text-red-600 text-sm">
                      {errors.setting_sec_one_desc}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="setting_sec_two_head" className="block text-sm font-medium leading-6 text-gray-900">
                  Second Head
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_two_head"
                    id="setting_sec_two_head"
                    value={values.setting_sec_two_head}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Second Head"
                  />
                  {errors.setting_sec_two_head && touched.setting_sec_two_head ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_two_head}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_two_icon" className="block text-sm font-medium leading-6 text-gray-900">
                  Second Head Icon
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_two_icon"
                    id="setting_sec_two_icon"
                    value={values.setting_sec_two_icon}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder=" Second Head Icon"
                  />
                  {errors.setting_sec_two_icon && touched.setting_sec_two_icon ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_two_icon}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_two_btn_title" className="block text-sm font-medium leading-6 text-gray-900">
                  Second Head Button Title
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_two_btn_title"
                    id="setting_sec_two_btn_title"
                    value={values.setting_sec_two_btn_title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Second Head Button Title"
                  />
                  {errors.setting_sec_two_btn_title && touched.setting_sec_two_btn_title ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_two_btn_title}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_two_btn_link" className="block text-sm font-medium leading-6 text-gray-900">
                  Second Head Button Link
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_two_btn_link"
                    id="setting_sec_two_btn_link"
                    value={values.setting_sec_two_btn_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Second Head Button Link"
                  />
                  {errors.setting_sec_two_btn_link && touched.setting_sec_two_btn_link ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_two_btn_link}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_two_desc" className="block text-sm font-medium leading-6 text-gray-900">
                  Second Head Desc.
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.setting_sec_two_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Second Head Desc."
                    name="setting_sec_two_desc"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.setting_sec_two_desc &&
                    touched.setting_sec_two_desc ? (
                    <p className="text-red-600 text-sm">
                      {errors.setting_sec_two_desc}
                    </p>
                  ) : null}
                </div>
              </div>


              <div>
                <label htmlFor="setting_sec_three_head" className="block text-sm font-medium leading-6 text-gray-900">
                  Third Head
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_three_head"
                    id="setting_sec_three_head"
                    value={values.setting_sec_three_head}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder="Third Head"
                  />
                  {errors.setting_sec_three_head && touched.setting_sec_three_head ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_three_head}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_three_icon" className="block text-sm font-medium leading-6 text-gray-900">
                  Third Head Icon
                </label>
                <div className="">
                  <input
                    type="text"
                    name="setting_sec_three_icon"
                    id="setting_sec_three_icon"
                    value={values.setting_sec_three_icon}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    placeholder=" Third Head Icon"
                  />
                  {errors.setting_sec_three_icon && touched.setting_sec_three_icon ? (
                    <div className="text-sm text-red-600">
                      {errors.setting_sec_three_icon}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="setting_sec_three_desc" className="block text-sm font-medium leading-6 text-gray-900">
                  Third Head Desc.
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.setting_sec_three_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Third Head Desc."
                    name="setting_sec_three_desc"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.setting_sec_three_desc &&
                    touched.setting_sec_three_desc ? (
                    <p className="text-red-600 text-sm">
                      {errors.setting_sec_three_desc}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="setting_is_active" className="block text-sm font-medium leading-6 text-gray-900">
                  Setting Is Active
                </label>
                <Switch
                  checked={settingIsActive}
                  onChange={setSettingIsActive}
                  className={classNames(
                    settingIsActive ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      settingIsActive ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
              >
                {setting_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
              </button>
            </div>
          </form>
        </div>

      </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddSetting