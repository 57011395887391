import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { brandSchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { brand_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import BrandServices from "../../../../ApiServices/BrandServices"
import MasterServices from "../../../../ApiServices/MasterServices";
import FileUploadWithPreview from '../../../../components/FileUploadWithPreview'; // Assuming this is your child component
import { doc_BASEURL } from "../../../../helper/constant.js";

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
function userStatusToggle(...classes) {
	return classes.filter(Boolean).join(" ");
}

function BrandList() {
	const pages = [{ title: "Brand List", href: "/brand", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [brandList, setBrandList] = useState([]);
	const [banner, setBanner] = useState(null);
	const [bannerName, setBannerName] = useState(null);
	const [tempBanner, setTempBanner] = useState(null);
	const [base64banner, setBase64banner] = useState(null);
	const [enabled, setEnabled] = useState(false)
	const [brand_id, setBrandid] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		brand_id: null,
	});
	const handleFileUpload = async (files) => {
		try {
			// Simulate file upload or handle it as needed
			// Example: Send files to backend via API
			console.log('Files to upload PS:', files);

			// Set upload status
			//   setUploadStatus('Files uploaded successfully');
			if (files?.flag == 1) {
				setBannerName(files?.files?.dynamicName)
				toast.success(files?.message);
			} else {
				toast.error(files.message)
			}
		} catch (error) {
			//   setUploadStatus('Error uploading files');
			console.error('Error uploading files:', error);
		}
	};
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "S.N. Hospital  • Brands";
		getAllBrands(3);
	}, []);


	const getAllBrands = async (payload) => {
		setIsLoading(true);
		try {
			const res = await BrandServices.getAllBrand(payload)
			if (res.status) {
				setBrandList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllBrands")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		brand_id: "",
		brand_image: "",
		brand_name: "",
		brand_is_position: "",
		brand_description: "",
	};

	const [formBrand, setFormBrand] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setBrandid(id);
			setEnabled(obj?.brand_is_published)
			setBase64banner(null)
			const initialValues = {
				brand_id: obj.brand_id,
				brand_image: obj.brand_image,
				brand_name: obj.brand_name,
				brand_description: obj.brand_description,
				brand_is_position: obj.brand_is_position,
			};
			setFormBrand(initialValues);
			setBannerName(obj.brand_image)
			setTempBanner(obj.brand_image)
		} else {
			setBrandid("");
			setBanner(null)
			setFormBrand(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	// const onFileChange = async (e) => {
	// 	const file = e.target.files;
	// 	console.log("file=>", file)
	// 	const formData = new FormData();
	// 	for (let i = 0; i < file.length; i++) {
	// 		formData.append('images', file[i]);
	// 	}

	// 	try {
	// 		let { data, status } = await MasterServices.uploadDoc(formData);

	// 		console.log('Files uploaded successfully:', data, status);
	// 	} catch (error) {
	// 		console.error('Error uploading files:', error);
	// 	}

	// 	// const file = e.target.files[0];
	// 	// const fileName = file.name;

	// 	// const reader = new FileReader();
	// 	// reader.onload = (e) => {
	// 	// 	setBanner(file)
	// 	// 	setTempBanner(e.target.result)
	// 	// 	setBase64banner(e.target.result)
	// 	// };
	// 	// reader.readAsDataURL(file);
	// };
	const onDeleteOpen = (brand_id) => {
		setConfirmationModal({ brand_id, status: true });
	};

	const onDeleteBrand = async (brand_id) => {
		setIsLoading(true);
		let { data, status } = await BrandServices.deleteBrand({ brand_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllBrands(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ brand_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formBrand,
			validationSchema: brandSchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					brand_id: brand_id,
					brand_name: values.brand_name,
					brand_description: values.brand_description,
					brand_is_position: values.brand_is_position,
					brand_image: bannerName,
				};
				if (brand_id === undefined || brand_id === null || brand_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertBrand(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertBrand(body);
				}
				action.resetForm();
				getAllBrands(3);
				if (modalOpenFlage === true) {
					setTempBanner(null)
					getAllBrands(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/brand");
			},
		});
	console.log("errors=", errors)
	const upsertBrand = async (payload) => {
		let { data, status } = await BrandServices.SaveBrand(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this brand?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteBrand(confirmationModal.brand_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Brand List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 16)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Brand
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={brand_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={brandList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{brand_id > 0 ? "Update" : "Add"} Brand
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="brand_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Brand Title
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.brand_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Brand Title"
																		name="brand_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.brand_name &&
																		touched.brand_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.brand_name}
																		</p>
																	) : null}
																</div>
															</div>


															<div className="my-2 sm:col-span-2 sm:mt-0">
																<div className="basis-1/2">
																	<label htmlFor="brand_image" className="block text-sm font-medium text-gray-700">
																		Banner
																	</label>
																	<FileUploadWithPreview onFileUpload={handleFileUpload} bannerName={bannerName} multiple={false}/>
																	{bannerName ? (<img height={40} width={40} className="preview-image" src={doc_BASEURL + bannerName} alt={`preview-${bannerName}`} />) : (<></>)}

																	{errors.brand_image &&
																		touched.brand_image ? (
																		<p className="text-red-600 text-sm">
																			{errors.brand_image}
																		</p>
																	) : null}
																</div>
															
															</div>


															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="brand_description"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Brand Description
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		rows="2"
																		value={values.brand_description}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Brand Long Description"
																		name="brand_description"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.brand_description &&
																		touched.brand_description ? (
																		<p className="text-red-600 text-sm">
																			{errors.brand_description}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="brand_is_position"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Brand Position {enabled}
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.brand_is_position}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="number"
																		placeholder="Enter Brand Position"
																		name="brand_is_position"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{/* <Switch
																		checked={enabled}
																		onChange={setEnabled}
																		className={userStatusToggle(
																			enabled ? "bg-[#00d4f6]" : "bg-gray-200",
																			"relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
																		)}
																	>
																		<span className="sr-only">Use setting</span>
																		<span
																			aria-hidden="true"
																			className={userStatusToggle(
																				enabled ? "translate-x-5" : "translate-x-0",
																				"pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
																			)}
																		/>
																	</Switch> */}
																</div>
															</div>

														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{brand_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default BrandList;
