import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { gallerySchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { gallery_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import GalleryServices from "../../../../ApiServices/GalleryServices"
import FileUploadWithPreview from '../../../../components/FileUploadWithPreview'; // Assuming this is your child component
import { doc_BASEURL } from "../../../../helper/constant.js";
import ImageGridModal from "../settings/ImageGridModal.jsx"
import Modal from 'react-modal';

function GalleryList() {
	const pages = [{ title: "Gallery List", href: "/gallery", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [galleryList, setGalleryList] = useState([]);
	const [gallery_id, setGalleryid] = useState(0);
	const [gallery_img, setGalleryImg] = useState([]);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		gallery_id: null,
	});
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	const openBannerModal = () => {
		setIsModalOpen(true);
	};

	const closeBannerModal = () => {
		setIsModalOpen(false);
	};
	const handleRemoveBanner = (e, index) => {
		const updatedBanners = gallery_img.filter((_, i) => i !== index);
		setGalleryImg(updatedBanners);
	};

	const handleBannerFileUpload = async (files) => {
		try {
			// Simulate file upload or handle it as needed
			// Example: Send files to backend via API
			console.log('Files to upload PS:', files);

			// Set upload status
			//   setUploadStatus('Files uploaded successfully');
			if (files?.flag == 1) {
				setGalleryImg(files?.files?.dynamicName.split(","))
				toast.success(files?.message);
			} else {
				toast.error(files.message)
			}
		} catch (error) {
			//   setUploadStatus('Error uploading files');
			console.error('Error uploading files:', error);
		}
	};

	useEffect(() => {
		document.title = "S.N. Hospital  • Gallerys";
		getAllGallerys(3);
	}, []);
	const getAllGallerys = async (payload) => {
		setIsLoading(true);
		try {
			const res = await GalleryServices.getAllGallery(payload)
			if (res.status) {
				setGalleryList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllGallery")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		gallery_id: "",
		gallery_name: "",
		gallery_img: "",
		gallery_is_active: "",
	};

	const [formGallery, setFormGallery] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setGalleryid(id);
			const initialValues = {
				gallery_id: obj.gallery_id,
				gallery_name: obj.gallery_name,
				gallery_img: obj.gallery_img,
				gallery_is_active: obj.gallery_is_active,
			};
			setFormGallery(initialValues);
			setGalleryImg(obj.gallery_img.split(","))
		} else {
			setGalleryid("");
			setFormGallery(initialValues);
			setGalleryImg(null);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (gallery_id) => {
		setConfirmationModal({ gallery_id, status: true });
	};

	const onDeleteGallery = async (gallery_id) => {
		setIsLoading(true);
		let { data, status } = await GalleryServices.deleteGallery({ gallery_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllGallerys(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ gallery_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formGallery,
			validationSchema: gallerySchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					gallery_id: gallery_id,
					gallery_is_active: true,
					gallery_name: values.gallery_name,
					gallery_img: gallery_img.join(","),
				};
				if (gallery_id === undefined || gallery_id === null || gallery_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertGallery(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertGallery(body);
				}
				action.resetForm();
				getAllGallerys(3);
				if (modalOpenFlage === true) {
					getAllGallerys(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/gallery");
			},
		});
	const upsertGallery = async (payload) => {
		let { data, status } = await GalleryServices.SaveGallery(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this cat?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteGallery(confirmationModal.gallery_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Gallery List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 70)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Gallery
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={gallery_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={galleryList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{gallery_id > 0 ? "Update" : "Add"} Gallery
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="gallery_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Gallery Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.gallery_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Gallery Name"
																		name="gallery_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.gallery_name &&
																		touched.gallery_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.gallery_name}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="gallery_img"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Gallery Description
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<FileUploadWithPreview onFileUpload={handleBannerFileUpload} bannerName={gallery_img?.length > 0 ? gallery_img : []} multiple={true} />
																	<button className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 mt-2 text-xs font-medium text-white shadow-sm hffff  ml-auto" onClick={(e) => { e.preventDefault(); openBannerModal() }}>View</button>
																	<ImageGridModal
																		images={gallery_img}
																		isOpen={isModalOpen}
																		onClose={closeBannerModal}
																		doc_BASEURL={doc_BASEURL}
																		setGalleryImg={setGalleryImg}
																		handleRemoveBanner={handleRemoveBanner}
																	/>
																	{errors.gallery_img &&
																		touched.gallery_img ? (
																		<p className="text-red-600 text-sm">
																			{errors.gallery_img}
																		</p>
																	) : null}
																</div>
															</div>

														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{gallery_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default GalleryList;
