/* eslint-disable */
import Api from "./Api";
export default {

  async SaveGallery(payload) {
    const response = await Api().post(`api/dashboard/gallery`, payload);
    return response;
  },

  async getAllGallery(payload) {
    const response = await Api().get(`api/dashboard/gallery?search=${payload}`);
    return response;
  },

  async getSingleGallery(payload) {
    const response = await Api().get(`api/dashboard/gallery/${payload}`);
    return response;
  },

  async setGalleryStatus(payload) {
    const response = await Api().post(`api/dashboard/gallery/status`, payload);
    return response;
  },

  async deleteGallery(payload) {
    const response = await Api().post(`api/dashboard/gallery/remove`, payload);
    return response;
  },

};
