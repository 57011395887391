import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { serviceSchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { service_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import ServiceServices from "../../../../ApiServices/ServiceServices"
import FileUploadWithPreview from '../../../../components/FileUploadWithPreview'; // Assuming this is your child component
import { doc_BASEURL } from "../../../../helper/constant.js";

function ServiceList() {
	const pages = [{ title: "Service List", href: "/service", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [serviceList, setServiceList] = useState([]);
	const [serv_id, setServiceid] = useState(0);
	const [serv_image, setServiceImg] = useState(null);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		serv_id: null,
	});
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "S.N. Hospital  • Services";
		getAllServices(3);
	}, []);
	const getAllServices = async (payload) => {
		setIsLoading(true);
		try {
			const res = await ServiceServices.getAllService(payload)
			if (res.status) {
				setServiceList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllServices")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		serv_id: "",
		serv_name: "",
		serv_amt: "",
		serv_description: "",
		serv_currency: "",
		serv_image:"",
		serv_is_active: "",
	};

	const [formService, setFormService] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setServiceid(id);
			const initialValues = {
				serv_id: obj.serv_id,
				serv_name: obj.serv_name,
				serv_description: obj.serv_description,
				serv_amt: obj.serv_amt,
				serv_currency: obj.serv_currency,
				serv_image: obj.serv_image,
				serv_is_active: obj.serv_is_active,
			};
			setServiceImg(obj.serv_image);
			setFormService(initialValues);
		} else {
			setServiceImg("");
			setServiceid("");
			setFormService(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const handleFileUpload = async (files) => {
		try {
			// Simulate file upload or handle it as needed
			// Example: Send files to backend via API
			console.log('Files to upload PS:', files);

			// Set upload status
			//   setUploadStatus('Files uploaded successfully');
			if (files?.flag == 1) {
				setServiceImg(files?.files?.dynamicName)
				toast.success(files?.message);
			} else {
				toast.error(files.message)
			}
		} catch (error) {
			//   setUploadStatus('Error uploading files');
			console.error('Error uploading files:', error);
		}
	};
	const onDeleteOpen = (serv_id) => {
		setConfirmationModal({ serv_id, status: true });
	};

	const onDeleteService = async (serv_id) => {
		setIsLoading(true);
		let { data, status } = await ServiceServices.deleteService({ serv_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllServices(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ serv_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formService,
			validationSchema: serviceSchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					serv_id: serv_id,
					serv_is_active: true,
					serv_name: values.serv_name,
					serv_description: values.serv_description,
					serv_amt: values.serv_amt,
					serv_currency: values.serv_currency,
					serv_image: serv_image,
				};
				if (serv_id === undefined || serv_id === null || serv_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertService(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertService(body);
				}
				action.resetForm();
				getAllServices(3);
				if (modalOpenFlage === true) {
					getAllServices(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/service");
			},
		});
	const upsertService = async (payload) => {
		let { data, status } = await ServiceServices.SaveService(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this service?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteService(confirmationModal.serv_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Service List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 40)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Service
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={service_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={serviceList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{serv_id > 0 ? "Update" : "Add"} Service
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="serv_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Service Name<span className="text-red-600 text-sm">*</span>
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.serv_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Service Name"
																		name="serv_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.serv_name &&
																		touched.serv_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.serv_name}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="tn_profile_image"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Profile
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<FileUploadWithPreview onFileUpload={handleFileUpload} bannerName={serv_image} multiple={false} />
																	{serv_image ? (<img height={40} width={40} className="preview-image" src={doc_BASEURL + serv_image} alt={`preview-${serv_image}`} />) : (<></>)}
																	{errors.serv_image &&
																		touched.serv_image ? (
																		<p className="text-red-600 text-sm">
																			{errors.serv_image}
																		</p>
																	) : null}

																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="serv_amt"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Service AMT
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.serv_amt}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="number"
																		placeholder="Enter Service AMT"
																		name="serv_amt"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.serv_amt &&
																		touched.serv_amt ? (
																		<p className="text-red-600 text-sm">
																			{errors.serv_amt}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="serv_currency"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Currency
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.serv_currency}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Currency"
																		name="serv_currency"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.serv_currency &&
																		touched.serv_currency ? (
																		<p className="text-red-600 text-sm">
																			{errors.serv_currency}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="serv_description"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Service Description<span className="text-red-600 text-sm">*</span>
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		rows="5"
																		value={values.serv_description}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Service Description"
																		name="serv_description"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.serv_description &&
																		touched.serv_description ? (
																		<p className="text-red-600 text-sm">
																			{errors.serv_description}
																		</p>
																	) : null}
																</div>
															</div>



														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{serv_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default ServiceList;
